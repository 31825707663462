import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { X } from '@b3networks/shared/common';
import { ID } from '@datorama/akita';
import { combineLatest, lastValueFrom, map, tap } from 'rxjs';
import { ChannelType, Inbox } from '../inboxes/inboxes.model';
import { TxnChannel } from '../txn/txn.model';
import { ConnectedOrgInfo } from './connected-org.model';
import { ConnectedOrgsQuery } from './connected-org.query';
import { ConnectedOrgsStore } from './connected-org.store';

@Injectable({
  providedIn: 'root'
})
export class ConnectedOrgsService {
  constructor(
    private http: HttpClient,
    private store: ConnectedOrgsStore,
    private connectedOrgsQuery: ConnectedOrgsQuery
  ) {}

  setActive(id: string) {
    this.store.setActive(id);
  }

  removeActive(id: string | ID) {
    this.store.removeActive(id);
  }

  getConnectedOrgInboxes(connectedOrgUuid: string, channel: ChannelType) {
    const params = new HttpParams().append('channel', channel);
    return this.http
      .get<ConnectedOrgInfo[]>(`inbox/private/v2/inboxes/connectedOrg/${connectedOrgUuid}`, {
        params: params
      })
      .pipe(
        map(connectedOrgs =>
          connectedOrgs.map(
            s =>
              new ConnectedOrgInfo({
                ...s,
                channel: channel,
                connectedOrgUuid
              })
          )
        ),
        tap(connectedOrgInboxes => {
          this.store.upsertMany(connectedOrgInboxes, { baseClass: ConnectedOrgInfo });
        })
      );
  }

  async getAllInboxesNoCheckLicense(): Promise<Inbox[]> {
    return await lastValueFrom(
      combineLatest([
        this.getConnectedOrgInboxes(X.orgUuid, ChannelType.CALL),
        this.getConnectedOrgInboxes(X.orgUuid, ChannelType.LIVE_CHAT),
        this.getConnectedOrgInboxes(X.orgUuid, ChannelType.WHATSAPP),
        this.getConnectedOrgInboxes(X.orgUuid, ChannelType.SUPPORT_CENTER)
      ]).pipe(
        map(([calls, liveChats, whatsapps, supportCenter]) => {
          const inboxesCall = this._mapInbox(calls);
          const inboxesLiveChat = this._mapInbox(liveChats);
          const inboxesWhatsapp = this._mapInbox(whatsapps);
          const inboxesSupportCenter = this._mapInbox(supportCenter);
          const listInboxes = [...inboxesCall, ...inboxesLiveChat, ...inboxesWhatsapp, ...inboxesSupportCenter];

          this.store.update({ listInboxes: listInboxes });

          return listInboxes;
        })
      )
    );
  }

  private _mapInbox(inboxesData: ConnectedOrgInfo[]): Inbox[] {
    return inboxesData.map(
      item =>
        new Inbox({
          uuid: item.inboxUuid,
          name: item.inboxName,
          channel: item.channel as unknown as TxnChannel
        })
    );
  }
}
